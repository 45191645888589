//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card(
    $card-gap-width: 0,
    $card-heading-font-size: $font-size-h2,
    $card-border-radius: 0,
    $card-border: 0,
    $card-details-padding: $spacer $spacer*2
    );
  @include card-side-by-side (
    $card-side-by-side-image-position: right, // 1375798 SA - align text on the left and images on the right
  )
}

// .homeFeatures .homeFeature:nth-child(even) {
//   @include card(
//     $card-gap-width: 0,
//     $card-heading-font-size: $font-size-h2,
//     $card-border-radius: 0,
//     $card-border: 0,
//     $card-details-padding: $spacer $spacer*2
//   );
//   @include card-side-by-side (
//     $card-side-by-side-image-position: right,
//   )
// }

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, not embedded map results)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}