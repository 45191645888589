//
// Tint and shade
//
@function tint($color, $percentage) {
    @return mix(white, $color, 100% - $percentage);
}

@function shade($color, $percentage) {
    @return mix(black, $color, 100% - $percentage);
}


//
// Environment
//



//
// Config values
//
$platform-name: 'haringeygiving';



//
// Colours
//

$brand-primary: #71d9c7;
$brand-secondary: #71d9c7;

$donate-colour: $brand-primary !default;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #F0F0F0;
$white:             #fff !default;


// Utility colours


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;
$border-radius: 5px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1180px,
  xl: 1200px
);


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: "logonew.svg";
$logo-width: 328px;
$logo-height: 115px;
$logo-absolute: true; // true if we want the logo to vertically intersect with the menu
// $logo-breakpoint: map-get($breakpoints, xs);


//
// Typography
//
$font-family-base: 'Karla', sans-serif;
$font-size-base: 1.125rem;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-letter-spacing: -0.05em;
$headings-margin-top: 1.5em; // This will only affect headings within post content
$headings-colour: $grey-dark;

// Links
$link-colour: #3c82a9;
$link-hover-decoration: none;

$footer-link-decoration: none;
$footer-link-hover-decoration: none;

// Icon font


//
// Buttons
//


// Donate button


//
// Social icons
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true

$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $grey-dark; // A colour, or 'brand'
$social-icons-hover-colour: $grey; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//


// Field groups


//
// Tables
//



//
// Card
//

$card-text-align: left;
$card-details-background-colour: $grey-lightest;
$card-border: 0;
$card-summary-colour: $grey-dark;

// Card text overlay

// Card text over

// Card side by side
$card-side-by-side-vertical-text-position: center; // top, center or bottom


// Card hover state
$card-image-background-colour: black;
$card-image-opacity: .9;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.0075;

//
// Menu admin
//

$menu-admin-enabled: false;


//
// Header
//


// Header content
$header-content-max-width: 100%; // 100%
$header-content-margin-bottom: 0;

// Tagline

// Main call to action

// Search

// Search - input

// Search - button
$header-search-button-background-colour: transparent;
$nav-search-button-icon-colour: text-contrast($brand-primary);
// Social icons


//
// Sticky header
//



//
// Navigation
//

$nav-background-colour: transparent;


// Top level items
$nav-top-level-item-colour: $link-colour;
$nav-top-level-item-font-weight: bold;
$nav-top-level-item-hover-background-colour: $grey-lightest;
$nav-top-level-item-padding: 10px 13px;

// Submenus
$nav-submenu-item-colour: $link-colour;

// Burger button

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-top: $spacer * 1;
$nav-normal-margin-bottom: $spacer * .5;
$nav-normal-align-items: right; // left, center, right
$nav-normal-contents-max-width: 100%;

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: true !default;
$nav-parent-clickable-chevron-background-colour: $brand-primary;
$nav-parent-clickable-chevron-colour: #292b2c;
$nav-parent-clickable-chevron-icon-type: "chevron" !default; // can take "chevron" or "plus"
$nav-parent-clickable-chevron-active-background-colour: $brand-primary;
$nav-parent-clickable-chevron-active-colour: #292b2c;


//
// Carousel
//

$carousel-max-width: 100%;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 500px;
$carousel-heading-font-size: $font-size-h1;
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-image-overlay: linear-gradient(78.43deg, rgba(0, 0, 0, 0.6) 15.31%, rgba(0, 0, 0, 0.5) 26.98%, rgba(0, 0, 0, 0.25) 43.72%, rgba(0, 0, 0, 0) 60%); // Add a colour value or gradient here
$carousel-summary-font-size: 1.25em;
$carousel-read-more-font-size: 0.8em;
$carousel-border-radius: 0;


// Carousel controls (left-right buttons)
$carousel-controls-margin-y: 5rem;
$carousel-controls-margin-x: 1rem;
$carousel-controls-button-gap: 5px;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel after breakpoint


//
// Home intro
//

$home-intro-margin-top: 0;
$home-intro-background-colour: transparent;
$home-intro-padding-y: $spacer * 3;
$home-intro-font-family: 'Gochi Hand', cursive; // Will affect all elements within the home intro snippet
$home-intro-font-weight: normal;
$home-intro-font-size: $font-size-h1;
$home-intro-line-height: 1.2;
// $home-intro-content-align: left; // left, center or right
$home-intro-colour: $grey-dark;


//
// Home features
//
$home-features-padding-top: $spacer * 1;



//
// Impact stats
//
$impact-stats-background-image: 'impact-24.jpg';
$impact-stats-background-colour: black;
$impact-stats-heading-enabled: false; //true or false
$impact-stats-margin-top: -3rem;
$impact-stats-padding-y: $spacer * 15;
$impact-stats-breakpoint: 980px;
$impact-stat-margin: $spacer*4;
// Heading

// Individual stat

// Figure

// Summary


//
// Home feeds
//


// Feeds title
$feeds-title-text-align: center; // left, center, right

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-prefab: 4;
$footer-background-colour: $grey-lightest;
$footer-padding-y: $spacer * 3;

// Footer admin links

// Newsletter
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-background-colour: transparent;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-margin-bottom: $spacer * 1;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer * 2;


//
// Page title
//

$page-title-margin-top: 1em;
$page-title-text-align: center; // left, center or right
$page-title-margin-bottom: 1em;



//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts


// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
$breadcrumb-enabled: false;



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//

// Donation amounts


//
// Quick giving panel
//



//
// Home quick giving panel
//
// $home-quick-giving-background-image: 'red-watercolor-heart-2-1-300x267.png';
$home-quick-giving-max-width: $container-max-width;
$home-quick-giving-content-max-width: $container-max-width;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//
$shop-enabled: true; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)


// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite nav


//
// Cookie consent
//

