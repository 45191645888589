// font-family: 'Caveat Brush', cursive;
// font-family: 'Nunito', sans-serif;

// Maintains aspect ratio 16:9 for video on mobile
.postVideoObjectEmbed,
.embed-container {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  margin: $spacer * 2 0;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// Add line to bottom of page header
header.pageHeader {
  border-bottom: 1px solid $grey-lighter;
}
// Accessibility CTA button in header
.mainCallToAction .cta-button.accessibility {
  background-color: $grey-lighter;
  color: $grey-dark;
}

// Divider images
@mixin divider($top-or-bottom, $rotation, $dim-x, $dim-y) {
  content: '';
  position: absolute;
  #{$top-or-bottom}: -1px;
  left: 0;
  width: 100%;
  height: calc((#{$dim-y} / #{$dim-x}) * 120vw);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 102%;
  transform: rotate($rotation);
}
.carouselSlide,
body:not(.page-no-banner) .carouselImageSingle {
  &:before {
    @include divider(bottom, 180deg, 650, 30);
    z-index: 1;
    background-image: url($assets-path+'paint-divider.svg');
  }
  .carouselSlideDetail {
    @media (max-width: map-get($breakpoints, lg)) {
      padding-bottom: 4em;
      .button.callToActionBtn,
      .readMore.carouselSlideReadMore {
        background-color: white;
        color: $brand-primary;
      }
    }
  }
  .carouselSlideHeading,
  .carouselSlideSummary {
    z-index: 2;
  }
}
.homefeaturecategory-homeboximpactstats {
  &:after {
    @include divider(bottom, 180deg, 650, 30);
    background-image: url($assets-path+'paint-divider.svg');
  }
  @media (max-width: map-get($breakpoints, lg)) {
    margin-top: 0;
  }
}
// Fix overflow for divider images
body {
  overflow-x: hidden;
}

// .mainLogo {
//     top:auto;
//     bottom: -4rem;
//     @media (max-width: map-get($breakpoints, lg)) {
//         margin-bottom: 1rem;
//     }
// }
// Home quick giving
.homefeaturecategory-homeboxquickgiving {
  z-index: 1;
  border-radius: 5px;
  .homepage-quickgiving--container {
    // justify-content: flex-end;
    // padding-right: 5rem;
    // @media (max-width: map-get($breakpoints, lg)) {
    //   padding-right: 20px;
    // }
  }
  &:before {
    content: '';
    position: absolute;
    background-image: url($assets-path+'Teal-heart.svg');
    width: 750px;
    height: 700px;
    background-size: contain;
    background-repeat: no-repeat;
    top: -2rem;
    left: -2rem;
    transform: rotate(-5deg);
    @media (max-width: map-get($breakpoints, sm)) {
      transform: none;
    }
  }
}
// Reduce font size in footer
.footerBox {
  font-size: 0.875rem;
}
.footerBox2 ul {
  column-count: 2;
}
// Add line between feeds
.homeFeedBox2,  .homeFeedBox3 {
  border-top: 1px solid $grey-lighter;
}

// 539587 - Remove the 'Heart Shape' Logo from the Homepage Intro
// .homeIntro {
//   padding-top: $spacer * 3;
//   p:before {
//     content: '';
//     position: absolute;
//     background-image: url($assets-path+'Teal-heart.svg');
//     width: 115%;
//     height: 150px;
//     top: -4rem;
//     @media (max-width: map-get($breakpoints, md)) {
//       height: 75px;
//       top: -2rem;
//     }
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
//     left: 0;
//     transform: rotate(-5deg);
//     @media (max-width: map-get($breakpoints, sm)) {
//       transform: none;
//     }
//     z-index: 1;
//     margin-left: auto;
//     margin-right: auto;
//   }
//   p {
//     max-width: $post-content-max-width;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

// Donate button
.cta-button.donate,
//.donate, -- 1043020 SA - Intro text on the 'Make a Donation' appeal is appearing in bold text
.button.donateBtn,
.buttonDonate
.donateBtn {
  font-weight: bold;
}


// Homepage quick giving
@media (min-width: map-get($breakpoints, md) + 1px) {
  .homefeaturecategory-homeboxquickgiving .homepage-quickgiving--copy {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 0;
    padding: 0 20px;
    > h2 { 
      flex: 1; 
      text-align: center;
    }
    > .quickGivingPanel {
      flex: 2;
      text-align: right;;
    }
  }
}

.pageFooterWrapper {
  margin-top: $spacer * 4;
  position: relative;
  &:before {
    @include divider(top, 180deg, 650, 30);
    background-image: url($assets-path+'grey-paint-divider.svg');
    top: auto;
    bottom: calc(100% - 1px);
  }
}


.homefeaturecategory-homeboximpactstats tr {
  margin: 2rem 0;
}

@media (max-width: map-get($breakpoints, lg)) {
  .pageHeader .headerContent {
    padding-bottom: $spacer;
  }
}
@media (max-width: map-get($breakpoints, sm)) {
  .pageHeader .headerContent {
    margin-top: $spacer * 0.5;
    padding-bottom: $spacer * 0.5;
  }
}

// https://raisingit.atlassian.net/browse/IM-1138
.menuMainAlt {
  font-size: 1.2rem;
}

.post .pageContent .postFooterWrapper .postFooter .publishDetailsWrapper {
  display: block;
}

.post .newsContent .postFooterWrapper .postFooter .publishDetailsWrapper {
  display: block;
}

.post .blogContent .postFooterWrapper .postFooter .publishDetailsWrapper {
  display: block;
}

.updatedDateWrapper, .publishAuthorWrapper{
  display: none;
}

//539586 - Add a 'Read more' Button to All HomeBoxes
.homeFeatureDetailsWrapper span.readMore{
  width: fit-content;
  margin-top: 1rem;
  align-self: flex-end;
}

//943775 SA - Quickgiving snippet update
.homepage-quickgiving--container {
  &:before {
    content: 'Get involved';
    display: block;
    font-size: $font-size-h2;
    font-weight: bold;
    color: #3c82a9;
    position: absolute;
    top:20px;

    @media screen and (min-width: 768px){
      top: 20px;
      left: 26%;
    }
  }
}

.homefeaturecategory-homeboxquickgiving {
  &:after {
    content: 'Your support today will help change lives in the heart of your community.';
    font-size: 1rem;
    position: absolute;
    left: 20px;
    right: 20px;
    margin-top: 8px;

    @media screen and (min-width: 768px){
      left: 26%;
      right: 20%;
      font-size: 1.125rem;
    }
  }
}

//946935 SA - homepage Hub snippet layout
section.homeFeature.homeFeatureSnippets.homeBox3 {
  grid-template-columns: 100%;

  blockquote {
    width: 99%;
    max-width: 1230px;
    margin-top: 0;
  }

  @media screen and (max-width: 576px) {
    blockquote {
      font-size: 1.5em;
    }
  }
}

// 1310420 SA - Homepage Hub snippet positioning
.homeBox2  {
  position: relative;
  padding: 0 20px;
  width: 100%;
  max-width: 1230px;
  line-height: 1.2em;
  font-size: 1.5em;
}